<template>
  <div>
    <div>
      <a-form-model ref="searchForm" :model="searchForm" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-row gutter="24">
          <a-col :span="8">
            <a-form-model-item label="查询日期">
              <a-range-picker allowClear />
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <a-form-model-item>
              <a-button type="primary">查询</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>

    <div style="margin-bottom: 16px;">
      <sales-amount-chart />
    </div>

    <div style="margin-bottom: 16px;">
      <sales-quantity-chart />
    </div>

  </div>
</template>

<script>
  export default {
    name: 'RankingPane',
    components: {
      SalesAmountChart: () => import('./salesAmountChart'),
      SalesQuantityChart: () => import('./salesQuantityChart'),
    },
    data() {
      return {
        searchForm: {

        },
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      };
    },
    methods: {
    },
  }
</script>

<style scoped>
  .card-buttom {
    margin: -4px 0;
  }
</style>